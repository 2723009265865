<template>
    <v-row class="mt-1 mx-3">
        <v-col cols="3">
          <v-select
              v-model="outlet.province"
              :items="provinces"
              :loading="mDistrictLoading"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
              clearable
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.district"
              :items="districts"
              :loading="mDistrictLoading"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.dsd"
              :items="dsdList"
              :loading="mDSDLoading"
              label="DSD"
              placeholder="Select DSD"
              variant="outlined"
              @update:modelValue="getAllGnd"

          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.gnd"
              :items="gndList"
              label="GND"
              placeholder="Select GND"
              variant="outlined"
          ></v-select>
        </v-col>
    </v-row>
    <v-row class="mx-3">
        <v-col cols="12">
          <Map :center="mapCenter" :zoom="12" class="d-flex map-styles">

          <Marker
                
                v-for="outlet in filteredOutlets"
                :key="outlet.id"
                :position="{ lat: parseFloat(outlet.latitude), lng: parseFloat(outlet.longitude) }"
                :title="outlet.outlet_name"
                :icon="{
                    url: require('@/assets/ICONS/maker.png'), 
                    scaledSize: { width: 30, height: 25 }, 
                }"  
              >
          </Marker>
          </Map>
        </v-col>
    </v-row>
</template>
    
<script>

import { Map, Marker,  } from '@fawmi/vue-google-maps';
import axios from "axios";
export default {
    components: {
        Map,
        Marker,
        
    },
    name: 'OutletMapDashboardComponentFour',

  data(){

      return{
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],

        outlet: {
          province: '',
          district: '',
          dsd: '',
          gnd: '',

        },
        
      mapCenter: { lat: 6.8738188, lng: 79.9931036 }, // Default map center
      outlets: [], 
      }
  },

  mounted() {
    this.getAllProvinces();
    this.getOutletLocation();
  },

  computed: {
    // filteredOutlets() {
    //   return this.outlets.filter(outlet => outlet && outlet.id && outlet.latitude && outlet.longitude);
      
    // }
    filteredOutlets() {
      console.log('Selected filters:', this.outlet);
      console.log('All outlets:', this.outlets);

      return this.outlets.filter((outlet) => {
        console.log('Filtering outlet:', outlet);
        return (
          (!this.outlet.province || outlet.province === this.outlet.province) &&
          (!this.outlet.district || outlet.district === this.outlet.district) &&
          (!this.outlet.dsd || outlet.dsd === this.outlet.dsd) &&
          (!this.outlet.gnd || outlet.gnd === this.outlet.gnd)
        );
      });
    },
    
  },
 
  methods: {

    getOutletLocation() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getOutletLocationDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.outlets = response.data.data;
          this.setMapCenter();
          console.log('data',this.outlets);
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
      },
      setMapCenter() {
            if (this.outlets.length > 0) {
              // Center the map based on the first outlet location
              const firstOutlet = this.outlets[0];
              this.mapCenter = {
                lat: parseFloat(firstOutlet.latitude),
                lng: parseFloat(firstOutlet.longitude),
              };
            }
          },
  getAllProvinces() {
    this.districts = [];
    this.dsdList = [];
    this.gndList = [];
    this.mDistrictLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.provinces = response.data.data;
            this.mDistrictLoading = false;
          }
        })
        .catch(() => {
          this.mDistrictLoading = false;
        });
  },

  getAllDistrictAccProv(province) {
    console.log('Province selected:', province);
    this.districts = [];
    this.mDistrictLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const headers = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
        .then(response => {
          console.log('District API response:', response.data);
          if (response.data.http_status === 'success') {
            this.districts = response.data.data;
            console.log('District API response:', response.data);
            this.mDistrictLoading = false;

          }
        })
        .catch(error => {
          this.mDistrictLoading = false;
          console.error('Error fetching districts:', error);

        });
  },

  getAllDsd(district) {
    this.dsdList = [];
    this.mDSDLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.dsdList = response.data.data;
            this.mDSDLoading = false;
          }
        })
        .catch(() => {
          this.mDSDLoading = false;
        });
  },

  getAllGnd(dsd) {
    this.gndList = [];
    this.mGNDLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.gndList = response.data.data;
            this.mGNDLoading = false;
          }
        })
        .catch(() => {
          this.mGNDLoading = false;
        });
  },
},
  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  },
  
};
</script>
    
<style scoped>
.v-card-title {
    font-weight: bold;
}
.map-styles {
  width: 100%;
  height: 400px;

}
</style>
    